module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"aswa-web","short_name":"aswa-web","start_url":"/","background_color":"#663399","theme_color":"#663399","display":"minimal-ui","icon":"./src/images/favicon.png","cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"cc50d98a30f4d73f1c484124cbd0d0d3"},
    },{
      plugin: require('../node_modules/gatsby-plugin-firebase/gatsby-browser.js'),
      options: {"plugins":[],"features":{"database":true,"firestore":true},"credentials":{}},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-NHSR9HJ","includeInDevelopment":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-netlify-cms/gatsby-browser.js'),
      options: {"plugins":[],"enableIdentityWidget":false,"modulePath":"/builds/asociace-softwarovych-agentur/aswa-web/cms/cms.js","manualInit":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-sentry/gatsby-browser.js'),
      options: {"plugins":[],"dsn":"https://6c65c382799b4a67863661027a7ef65f@o229182.ingest.sentry.io/5782169","enabled":true,"environment":"07d541ef14872423463dee3316f229f3ec2c19af"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
