/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it

exports.onClientEntry = () => {
	window.previousPath = "/"
}

exports.onRouteUpdate = () => {
	window.locations = window.locations || [document.referrer]
	window.locations.push(window.location.href)
	window.previousPath = window.locations[window.locations.length - 1].replace(
		window.location.origin,
		""
	)
}
